export default {
  options: {
    type_options: [
      { value: "FREEGIFT", label: "FREE GIFT" },
      { value: "GIFTCARD", label: "GIFT CARD" },
      { value: "CASHBACK", label: "CASHBACK" }
    ],
    status_options: [
      { value: "A", label: "启用" },
      { value: "I", label: "禁用" }
    ],
    boolean_options: [
      { value: true, label: "是" },
      { value: false, label: "否" }
    ],
    rating_options: [
      { value: "1", label: "1星以上" },
      { value: "2", label: "2星以上" },
      { value: "3", label: "3星以上" },
      { value: "4", label: "4星以上" },
      { value: "5", label: "5星" }
    ],
    tags_options: [
      { value: "保修激活", label: "保修激活", color: "#e7e3a3" },
      { value: "确认礼品", label: "确认礼品", color: "#c5e7a3" },
      { value: "填写问卷", label: "填写问卷", color: "#e7cda3" },
      { value: "系统留评", label: "系统留评", color: "#c8a3e7" },
      { value: "引导至亚马逊", label: "引导至亚马逊", color: "#a3b3e7" },
      { value: "确认留评", label: "确认留评", color: "#f6f2d1" },
      { value: "提交收货信息", label: "提交收货信息", color: "lightblue" }
    ],
    order_status_options: [
      { value: "AWAITING_SHIPMENT", label: "待发送礼品" },
      { value: "SHIPPED", label: "已发送礼品" }
    ]
  }
};
