import request from "@/utils/request";
export { getExtraHeaders } from "../utils/request";

// 取得activity列表
export function getActivitylist(data) {
  return request({
    url: "/activity/list-activity",
    method: "post",
    data
  });
}

// 取得activity
export function getActivitybyPK(data) {
  return request({
    url: "/activity/get-activity-by-pk",
    method: "post",
    data
  });
}

// 更新 activity
export function updateActivitybyPK(data) {
  return request({
    url: "/activity/update-activity-by-pk",
    method: "post",
    data
  });
}

// 新增 activity
export function createActivity(data) {
  return request({
    url: "/activity/create-activity",
    method: "post",
    data
  });
}

// 删除activity
export function deleteActivitybyPK(data) {
  return request({
    url: "/activity/delete-activity-by-pk",
    method: "post",
    data
  });
}

// 启用
export function enableActivity(data) {
  return request({
    url: "/activity/enable-activity",
    method: "post",
    data
  });
}

// 禁用
export function disableActivity(data) {
  return request({
    url: "/activity/disable-activity",
    method: "post",
    data
  });
}

// 图片上传地址
export function getUploadImgUrl() {
  return request.defaults.baseURL + "/file/upload-img";
}
