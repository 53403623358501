<template lang="pug">
    div.app-container
        //- 查询操作
        div.filter-container
            el-select(v-model="listQuery.type" placeholder="选择引导留评方式" clearable).filter-item.ml-5
              el-option( v-for="item in type_options" :key="item.value" :label="item.label" :value="item.value")
            el-button(:disabled="!listQuery.type" v-permission="['activity:activity:create']" type="primary" icon="el-icon-plus" @click="onCreate").filter-item.ml-5 添加
            el-select(v-model="listQuery.asin" placeholder="请选择ASIN" filterable clearable).filter-item.ml-5
              el-option(v-for="item in skuAsinOptions" :key="item.asin" :value="item.asin" :label="item.asin")
                span
                  b {{item.asin}}
                span.float-right(style="display:inline-block;width:150px;text-overflow:ellipsis;") {{item.title}}
            el-button(v-permission="['activity:activity:list']" type="primary" icon="el-icon-search" @click="onFilter").filter-item.ml-5 查找

        //- 查询结果 
        el-table(v-loading="listLoading" :data="list" size="small" element-loading-text="正在查询中。。。" border fit highlight-current-row)
            el-table-column(align="right" label="活动类型" prop="type" sortable width="120px")
            el-table-column(align="right" label="对应ASIN" prop="asin" sortable width="120px")
            el-table-column(align="right" label="活动名称" prop="name" sortable)
            el-table-column(align="right" label="礼品名称" prop="gift_name" sortable)
            el-table-column(align="right" label="描述" prop="description" sortable)
            el-table-column(align="right" label="有效期至" prop="expiry_date" sortable width="100px")
              template(slot-scope="scope") {{scope.row.expiry_date | formatDate}}
              
            el-table-column(align="left" label="是否有效" prop="status" sortable width="100px")
              template(slot-scope="scope")
                span(v-if="scope.row.status=='I'") 已禁用
                span(v-else).red 生效中


            el-table-column(align="center" label="操作").small-padding.fixed-width
                template(slot-scope="scope")
                    el-link(v-if="scope.row.status=='I'" v-permission="['activity:activity:enable']" type="primary" size="mini" @click="onEnable(scope.row)") 启用
                    el-link(v-else v-permission="['activity:activity:disable']" type="primary" size="mini" @click="onDisable(scope.row)") 禁用
                    el-link.opt-link(v-permission="['activity:activity:edit']" type="primary" size="mini" @click="onUpdate(scope.row)") 编辑
                    el-link.opt-link(v-permission="['activity:activity:delete']" type="primary" size="mini" @click="onDelete(scope.row)") 删除

        //- 分页
        pagination(v-show="total>0" :total="total"  :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList")

        //- 弹出层 - 修改订单
        el-dialog(:visible.sync="dialogForm" :title="form.title" :close-on-click-modal="false").pupup-form
          el-form(:inline="true" :model="form" label-width="130px" ref="form" :rules="formRules" )
            el-form-item(label="活动名称" prop="name")
              el-input(v-model="form.name")
            el-form-item(label="礼品名称" prop="gift_name")
              el-input(v-model="form.gift_name")
            el-form-item(label="描述" prop="description")
              el-input(v-model="form.description")
            el-form-item(label="对应ASIN" prop="asin")
              el-select(v-model="form.asin" placeholder="请选择ASIN" filterable)
                el-option(v-for="item in skuAsinOptions" :key="item.asin" :value="item.asin" :label="item.asin")
                  span
                    b {{item.asin}}
                  span.float-right(style="display:inline-block;width:150px;text-overflow:ellipsis;") {{item.title}}
            el-form-item(label="图片" prop="gift_img")
              el-upload(class="avatar-uploader" accept=".jpeg,.png,.jpg" :action="uploadForm.uploadUrl" :headers = "uploadForm.headers" :show-file-list="false" :on-success="onUploadSuccess")
                img(v-if="form.gift_img" :src="form.gift_img" class="avatar")
                i(v-else class="el-icon-plus avatar-uploader-icon")
                p(slot="tip") (建议图片大小 315px * 315px)
            el-form-item(label="状态" prop="status")
              el-select(v-model="form.status" placeholder="请选择").filter-item.ml-5
                el-option( v-for="item in status_options" :key="item.value" :label="item.label" :value="item.value")
            el-form-item(v-if="form.type=='CASHBACK'" label="返现金额" prop="cashback_amt")
              el-input(v-model="form.cashback_amt")
                template(slot="prepend") $
              p.i.red (设置返现金额须业务负责人审批)
            el-form-item(v-if="form.type=='GIFTCARD'" label="GIFT CARD金额" prop="giftcard_amt")
              el-input(v-model="form.giftcard_amt")
                template(slot="prepend") $
              p.i.red (所设置的礼品卡金额须经过业务负责人审批)
            el-form-item(label="有效期至" prop="expiry_date")
              el-date-picker(v-model="form.expiry_date" type="date" placeholder="选择日期")
            el-form-item(label="购买价格要求" prop="price_limit")
              el-input(v-model="form.price_limit")
                template(slot="prepend") $
            el-form-item(label="是否包含测评订单")
              el-select(v-model="form.is_include_evaluation" placeholder="请选择").filter-item.ml-5
                el-option( v-for="item in boolean_options" :key="item.value" :label="item.label" :value="item.value")

          div(slot="footer").dialog-footer
            el-button(@click="onUpdateCancel") 取消
            el-button(v-if="formMode=='create'" v-permission="['activity:activity:create']" :loading="form.loading" @click="onCreateSave" type="primary") 确认
            el-button(v-else v-permission="['activity:activity:update']" :loading="form.loading" @click="onUpdateSave" type="primary") 确认

</template>

<script>
import * as activity from "../../api/activity";
import * as sku from "../../api/sku";

import Pagination from "@/components/pagination"; // Secondary package based on el-pagination
import AppConfig from "../../app-config";

export default {
  name: "ActivityList",
  components: { Pagination },
  data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      ...AppConfig.options,
      listQuery: {
        type: null,
        page: 1,
        limit: 20,
        keyword: undefined,
        sort: "creation_date",
        order: "desc"
      },
      skuAsinOptions: [],
      uploadForm: {
        loading: false,
        rtn: null,
        hasFile: false,
        headers: sku.getExtraHeaders(),
        uploadUrl: sku.getUploadImgUrl(),
        filelist: []
      },
      dialogForm: false,
      formMode: "create",
      form: {},
      formRules: {
        name: {
          required: true,
          message: "请填写活动名称",
          trigger: "change"
        },
        gift_name: {
          required: true,
          message: "请填写礼品名称(限英文)",
          pattern: /^[A-z0-9.$\s]+$/,
          trigger: "change"
        },
        gift_img: {
          required: true,
          message: "上传图片",
          trigger: "change"
        },
        description: {
          required: true,
          message: "请填写描述",
          trigger: "change"
        },
        asin: {
          required: true,
          message: "请填写ASIN",
          trigger: "change"
        },
        status: {
          required: true,
          message: "请选择状态",
          trigger: "change"
        },
        cashback_amt: {
          required: true,
          pattern: /^\d*(\.\d{1,2})?$/,
          message: "请输入两位小数的数字",
          trigger: "change"
        },
        giftcard_amt: {
          required: true,
          pattern: /^\d*(\.\d{1,2})?$/,
          message: "请输入两位小数的数字",
          trigger: "change"
        },
        price_limit: {
          required: true,
          pattern: /^\d*(\.\d{1,2})?$/,
          message: "请输入两位小数的数字",
          trigger: "change"
        },
        expiry_date: {
          required: true,
          message: "请填写有效期",
          trigger: "change"
        }
      }
    };
  },
  created() {
    this.getList();
    this.getAsinOptions();
  },
  computed: {},
  methods: {
    async getAsinOptions() {
      const res = await sku.getSkuAsinOptions();
      this.skuAsinOptions = res.data.data;
    },
    async getList() {
      this.listLoading = true;
      try {
        const resp = await activity.getActivitylist(this.listQuery);
        this.list = resp.data.data.items;
        this.total = resp.data.data.total;
        this.listLoading = false;
      } catch (error) {
        this.listLoading = false;
      }
    },
    getTitle(type) {
      let title = "N/A";
      if (type == "FREEGIFT") title = "实体礼品活动信息";
      if (type == "GIFTCARD") title = "GIFT CARD活动信息";
      if (type == "CASHBACK") title = "返现活动信息";
      return title;
    },
    onCreate() {
      this.formMode = "create";
      this.form = {};
      this.form = { gift_img: "" };
      this.form.type = this.listQuery.type;
      this.form.title = this.getTitle(this.form.type);
      this.dialogForm = true;
      this.getAsinOptions();
      this.$refs.form.resetFields();
    },
    async onCreateSave() {
      try {
        await this.$refs.form.validate();
        let res = await activity.createActivity(this.form);
        if (res.data.errno == 200) {
          // 提示操作成功
          this.$notify.success({
            title: "成功",
            message: `活动 :${this.form.name} 信息新增成功`
          });
          this.dialogForm = false;
          this.listQuery.type = null;
          this.getList();
        }
      } catch (error) {
        return false;
      }
    },
    onUploadSuccess(res) {
      this.form.gift_img = res.data.url;
    },
    async onEnable(row) {
      const res = await activity.enableActivity({ act_id: row.act_id });
      if (res.data.errno == 200) {
        // 提示操作成功
        this.$notify.success({
          title: "成功",
          message: `活动 :${row.name} 启用成功`
        });
        this.getList();
      }
    },
    async onDisable(row) {
      const res = await activity.disableActivity({ act_id: row.act_id });
      if (res.data.errno == 200) {
        // 提示操作成功
        this.$notify.success({
          title: "成功",
          message: `活动 :${row.name} 禁用成功`
        });
        this.getList();
      }
    },
    async onUpdate(row) {
      const res = await activity.getActivitybyPK({ act_id: row.act_id });
      this.form = res.data.data;
      this.formMode = "edit";
      this.form.title = this.getTitle(this.form.type);
      this.dialogForm = true;
      this.getAsinOptions();
      this.$refs.form && this.$refs.form.resetFields();
    },
    onUpdateCancel() {
      this.dialogForm = false;
    },
    async onUpdateSave() {
      try {
        await this.$refs.form.validate();
        const res = await activity.updateActivitybyPK(this.form);
        if (res.data.errno == 200) {
          // 提示操作成功
          this.$notify.success({
            title: "成功",
            message: `活动 :${this.form.name} 信息修改成功`
          });
          this.dialogForm = false;
          this.getList();
        }
      } catch (error) {
        return false;
      }
    },
    async onDelete(row) {
      try {
        // 询问是否进行
        await this.$confirm(
          "此操作将删除:" + row.name + ", 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }
        );

        // 执行操作
        const res = await activity.deleteActivitybyPK(row);

        if (res.data.errno == 200) {
          // 提示操作成功
          this.$notify.success({
            title: "成功",
            message: `活动 :${row.name} 信息删除成功`
          });
        }
        this.getList();
      } catch (e) {
        return false;
      }
    },
    onFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    onUploadFormChange() {
      this.uploadForm.hasFile = true;
    }
  }
};
</script>
